import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { loginUserAsync } from "../../../store/thunks/AuthThunk";
import { resetLoginStatus, STATUS } from "../../../store/slices/AuthSlice";
import Header from "../../common/Header";
import BackIcon from "../../../assets/BackIcon";
import PasswordInput from "../../common/PasswordInput";
import { getUserAccountDetailsAsync } from "../../../store/thunks/MasterThunk";
import { OnboardingState } from "../../../store/slices/MasterSlice";
import { Keyboard } from "@capacitor/keyboard";
import useDeviceInfo from "../../../hooks/useDeviceInfo";
import mixpanel, { identifyUser } from "../../../utils/mixpanel";
import HeaderBigLogo from "../../common/HeaderBigLogo";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const loginDetails = useSelector((state: RootState) => state.AuthStore);
    const { userAccount } = useSelector((state: RootState) => state.MasterStore);
    const deviceInfo = useDeviceInfo();
    useEffect(() => {
       
        if (deviceInfo?.platform === "ios") {

        const showListener = Keyboard.addListener("keyboardWillShow", (info) => {
            document.body.classList.add("keyboard-open");
        });
        const hideListener = Keyboard.addListener("keyboardWillHide", () => {
            document.body.classList.remove("keyboard-open");           
        });
        return () => {
            document.body.classList.remove("keyboard-open");
            Keyboard.removeAllListeners();
        };
    }
    }, [deviceInfo?.platform]);
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getUserAccountDetailsAsync());
        };
        if(localStorage.getItem('accessToken'))
        {
            fetchData();
        }
       
    }, [dispatch]);
    const handleLogin = async () => {
     
       const result = await dispatch(loginUserAsync({ email: email, password: password }));
       if (loginUserAsync.fulfilled.match(result))
       {
        const accessToken: string | undefined = result.payload?.access_token;
        identifyUser(accessToken, 'User Logged In');
        localStorage.setItem('accessToken', accessToken as string);
        const userresult =  await dispatch(getUserAccountDetailsAsync());

        if (getUserAccountDetailsAsync.fulfilled.match(userresult))
        {
            if (userresult.payload.onboarding_state ) {
                if (userresult.payload.onboarding_state === OnboardingState.ONBOARDING_COMPLETE) {
                    navigate('/home');
                } else if (userresult.payload.onboarding_state === OnboardingState.BANK_ACCOUNT_CONNECTED) {
                    navigate('/survey');
                } else {
                    navigate('/new-connect-account');
                }
            }
        }
        
       }
      
    };

    // useEffect(() => {
    //     const fetchData = () => {

    //         if (loginDetails.loginStatus === STATUS.SUCCESS ) {
    //             const accessToken: string | undefined = loginDetails?.loginResponse?.access_token;
    //             identifyUser(accessToken, 'User Logged In');
    //             localStorage.setItem('accessToken', accessToken as string);
    //             if (userAccount.onboarding_state ) {
    //                 if (userAccount.onboarding_state === OnboardingState.ONBOARDING_COMPLETE) {
    //                     navigate('/home');
    //                 } else if (userAccount.onboarding_state === OnboardingState.BANK_ACCOUNT_CONNECTED) {
    //                     navigate('/survey');
    //                 } else {
    //                     navigate('/new-connect-account');
    //                 }
    //             }
    //         } else if (loginDetails.loginStatus === STATUS.FAILED) {
    //             setEmail('')
    //             setPassword('')
    //             mixpanel.reset()
    //         }
    //     };

    //     fetchData();
    // }, [loginDetails, userAccount.onboarding_state]);

    return (
        <div className="flex flex-col pt-3 px-2.5 h-screen">
            <div onClick={() => navigate('/landing')}> <BackIcon /></div>
            <div className="mt-28"> <HeaderBigLogo /></div>
            <div className="texth2 flex justify-center">Sign In</div>

            <div className="carouselSubTitle flex justify-start items-start mt-6 ml-1">Email</div>
            <div>
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="text4 text-slate-900 bg-white rounded-md w-full border border-slate-300 h-[36px] px-3 focus:border-slate-300 focus:outline-none"

                />
            </div>

            <div className="carouselSubTitle flex justify-start items-start mt-2 ml-1">Password</div>
            <PasswordInput password={password} setPassword={setPassword} />
            <div onClick={() => {
                localStorage.removeItem('accessToken');
                navigate('/forgot-password')
            }} className="mt-6 text-center carouselSubTitle text-blue600 cursor-pointer">Forgot password</div>
            <button
                className="mt-6 bg-black text-white h-10 w-full rounded-full text-lg font-semibold"
                onClick={handleLogin}
            >
                <div className="carouselSubTitle">{loginDetails.loginStatus === STATUS.LOADING ? 'Signing in...' : 'Sign In'}</div>
            </button>
            {loginDetails.loginStatus === STATUS.FAILED && <div className="text-red-500 mt-1 text-center">Login failed. Please check your credentials</div>}
        </div>
    );
};

export default Login;
