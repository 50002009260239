import React, { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import BackIcon from "../../../assets/BackIcon";
import { useNavigate, useParams } from "react-router";
import coachesData from "./CoachInfo.json";

const SchedulingMeeting: React.FC = () => {
    const { coachId } = useParams();
    const navigate = useNavigate();
    const [coachUrl, setCoachUrl] = useState<string | null>(null);

    useEffect(() => {
        if (coachId) {
            const coach = coachesData.find((coach) => coach.CoachId === coachId);
            if (coach) {
                setCoachUrl(coach.url);
            }
        }
    }, [coachId]);
    return (
        <div className="pt-3 h-screen">
            <div className="flex pb-5">
                <div onClick={() => { navigate('/home?from=Meet Coach') }}>
                    <BackIcon />
                </div>
                <div className="mx-auto">
                    <div className="carouselTitle sticky top-0 z-10 py-2 px-4">
                        Book your session
                    </div>
                </div>
            </div>
            <div className="container w-full" style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
                <InlineWidget
                    url={coachUrl || ''}
                    styles={{ height: "100%", width: "100%" }}
                />
            </div>
        </div>
    );
};

export default SchedulingMeeting;
